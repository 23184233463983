.list-container {
  margin: 0;
  padding: 20px;
  list-style-type: none;
  background-color: var(--color-white);
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.container {
  width: 444px;
  position: absolute;
  z-index: 10;
}

.title {
  font-weight: bold;
  justify-content: left;
  font-size: 14px;
}

.holder {
  position: relative;
}

.subTitle {
  opacity: 0.4;
  color: var(--color-grey);
  justify-content: right;
  font-size: 12px;
  font-weight: bold;
}
