.main-content {
  display: flex;
  background-color: var(--main-page-color);
}

.sidebar-content {
  background-color: var(--color-primary);
  border-top-right-radius: 0px;
  box-shadow: 0 5px 14px 0 rgba(0, 4, 7, 0.17);
  height: 100vh;
  overflow: hidden;
}

.sidebar-wraper {
  background-color: var(--color-black);
  width: 15%;
  height: 100vh;
  position: fixed;
}

.dashboard-content {
  width: 85%;
  margin-left: 15%;
}
