[data-has-label='true'] + [data-has-label='true'] {
  margin-top: 22px;
}

[data-has-label='true'] + [data-has-label='false'] {
  margin-top: 20px;
}

.slick {
  letter-spacing: 0px;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--color-primary);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
.is-disabled {
  color: var(--color-black);
  opacity: 0.4;
  font-family: AvenirNext;
  font-size: 12px;
  line-height: 21px;
}
