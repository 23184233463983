.screen-loader {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 998;
  background: rgba(200, 200, 200, 0.5);
  height: 100%;
  width: 100%;
}

.screen-loader img {
  position: relative;
  top: 50%;
  left: 55%;
  width: 74px;
  height: 74px;
}
