.input {
  color: var(--color-secondary-font);
  cursor: text;
  border: none;
  width: 100%;
  outline-offset: 0ch;
  outline-color: transparent;
  box-sizing: border-box;
}

.bottom-border-only {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--input-border-color);
}
.bottom-border-only.has-error {
  border-bottom: 2px solid var(--color-danger);
}
.bottom-border-only.has-error {
  border-bottom: 2px solid var(--color-success);
}

.inp-block {
  display: block;
}

.inp-inline {
  display: inline;
}
.vanilla-border {
  background-color: var(--color-white);
  border: 1.5px solid var(--color-border-light-gray);
  border-radius: 5px;
  width: 500px;
  height: 50px;
  padding: 20px;
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
}
.vanilla-border.has-error {
  border: 2px solid var(--color-danger);
}
.vanilla-border.has-success {
  border: 2px solid var(--color-success);
}

.full-borders {
  background-color: var(--color-white);
  border: 1.5px solid var(--color-border-gray);
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  width: 444px;
  height: 36px;
  padding-left: 30px;
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.5029529389880952;
  text-align: left;
}
.full-borders.has-error {
  border: 2px solid var(--color-danger);
}
.full-borders.has-success {
  border: 2px solid var(--color-success);
}
.input-box {
  display: flex;
}
.input-success {
  margin-left: -32px;
  margin-top: 5px;
  color: var(--color-success);
  opacity: 1;
}
.input-prefix {
  position: absolute;
  padding-left: 5px;
  margin-top: 8px;
  color: var(--color-primary);
  opacity: 0.5;
}
.input-suffix {
  margin-left: -32px;
  align-self: center;
  color: var(--color-primary);
  opacity: 0.5;
}

.input-dropdown {
  margin-left: -32px;
  margin-top: 5px;
  color: var(--color-primary);
  opacity: 1;
}

.dropdown-pointer {
  cursor: pointer;
}
.input-pointer {
  cursor: text;
}

.copy {
  cursor: pointer !important;
  margin-left: -32px;
  margin-top: 5px;
  color: var(--color-primary);
  opacity: 0.8;
}
