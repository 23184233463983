.error-404-wrapper {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f4fd;
  z-index: 999;
}

.error-404-container {
  padding-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.error-404-inner {
  padding: 35px 20px;
}

.error-404-content {
  width: 1113px;
  height: 700px;
  border: 1px solid #dee2e7;
  box-shadow: 6px 4px 6px -2px rgba(0, 4, 7, 0.17);
  border-radius: 30px;

  background: url(not-found-bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000000;
  color: #fff;
}

.error-404-content .brand-area {
  display: block;
  text-align: left;
}

.error-404-content .brand-area .brand-logo {
  margin-left: 20px;
  height: 100px;
  transform: scale(1.2);
  display: block;
}

.error-404-content .text-area {
  display: block;
  text-align: center;
}

.error-404-content .text-area h1 {
  color: var(--color-dark);
  font-family: AvenirNext;
  font-size: 100px;
}

.error-404-content .text-area .error-404-description {
  color: var(--color-dark);
  font-family: AvenirNext;
  font-size: 24px;
  display: block;
  line-height: 29px;
}

.error-404-divider-container {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.error-404-divider {
  width: 100px;
  border-top: 2px solid #fff;
}

.return-from-error-page {
  color: var(--color-dark);
  text-decoration: underline;
}

.return-from-error-page:hover {
  color: var(--color-dark);
  text-decoration: none;
}
