.main-content {
  width: 100%;
  display: flex;
  position: relative;
  height: 100vh;
  background-color: var(--main-page-color);
}

.pane-wrapper {
  width: 1076px;
  min-height: 612px;
  display: flex;
  background-color: var(--color-white);
  border: 1px solid transparent;
  border-radius: 12px;
  box-shadow: 0 5px 14px 0 rgba(0, 4, 7, 0.17);
  overflow: hidden;
}

.left-pane {
  width: 435px;
  display: flex;
}

.left-pane aside {
  max-width: 300px;
}

.right-pane {
  width: 641px;
  display: flex;
  flex-direction: column;
}

.right-pane .backBar {
  padding: 15px;
  position: absolute;
}

.right-pane .content {
  margin: auto 0;
}

.blocktext {
  margin-top: 30px;
  line-height: 1.2;
  width: auto;
  padding-left: 3px;
  padding-right: 3px;
}

.text-centered {
  text-align: center;
}

.horizontally-centered {
  justify-content: center;
  margin: auto;
}

.vertically-centered {
  align-items: center;
}

.positioned-midway {
  margin: auto;
}

.adjusted-for-contrast {
  color: var(--color-white);
  background-color: transparent;
}

.image-decorated {
  background-color: rgba(0, 0, 0, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('../../assets/images/png/pexels-cottonbro-5076516.png');
}

.form-box {
  max-width: 445px;
  margin: auto;
}
