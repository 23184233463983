.image-box {
  display: inline-flex;
  overflow: hidden;
}

.image {
  object-fit: cover;
  width: 100%;
  display: inline-block;
  height: auto;
}

.sm {
  height: 30px;
}

.sm > .image {
  max-width: 30px;
}

.md {
  height: 60px;
}

.md > .image {
  max-width: 60px;
}

.lg {
  height: 90px;
}

.lg > .image {
  max-width: 90px;
}

.circle {
  border-radius: 50%;
}

.square {
  border-radius: 0;
}

.rounded {
  border-radius: 6px;
}
